<!-- 我要诉求 -->
<template>
  <div class="con">
    <mu-form ref="form" :model="postFormValue" class="mu-form">
      <mu-form-item class="searchDiv animated fadeInUp" style="animation-delay: 0.1s"  label="姓名" label-float prop="xm" :rules="xmRules">
        <mu-text-field v-model="postFormValue.xm" :change="postFormValue.xm = postFormValue.xm.replace(/\s+/g,'')" prop="xm"></mu-text-field>
      </mu-form-item>
      <mu-form-item class="searchDiv animated fadeInUp" style="animation-delay: 0.1s"  label="手机号码" label-float prop="phone" :rules="phoneRules">
        <mu-text-field v-model="postFormValue.phone" :change="postFormValue.phone = postFormValue.phone.replace(/\s+/g,'')" prop="phone"></mu-text-field>
      </mu-form-item>
      <mu-form-item class="searchDiv animated fadeInUp radioSelect" style="animation-delay: 0.1s"  label="性别" prop="sex">
        <mu-radio :value="'male'" v-model="postFormValue.sex"  :label="'男'"></mu-radio>
        <mu-radio :value="'female'" v-model="postFormValue.sex"  :label="'女'"></mu-radio>
      </mu-form-item>
      <mu-form-item class="searchDiv animated fadeInUp radioSelect" style="animation-delay: 0.1s"  label="是否保密" prop="isSecret">
        <mu-radio :value="true" v-model="postFormValue.isSecret"  :label="'是'"></mu-radio>
        <mu-radio :value="false" v-model="postFormValue.isSecret"  :label="'否'"></mu-radio>
      </mu-form-item>
      <!-- <mu-form-item class="searchDiv animated fadeInUp" style="animation-delay: 0.3s"  label="请选择类型" label-float prop="lxName"  :rules="lxRules">
        <mu-select filterable v-model="postFormValue.lxName" prop="lxName" >
          <mu-option v-for="(e, i) in leibie" :key="i" :label="e" :value="e"></mu-option>
        </mu-select>
      </mu-form-item> -->
      <mu-form-item class="searchDiv animated fadeInUp" style="animation-delay: 0.5s"  label="请输入标题" label-float prop="bt" :rules="btRules">
        <mu-text-field v-model="postFormValue.bt" label-float :change="postFormValue.bt"></mu-text-field>
      </mu-form-item>
      <mu-form-item class="fieldDiv animated fadeInUp" style="animation-delay: 0.7s"  label="请输入投诉内容" label-float prop="tsnr" :rules="tsnrRules">
        <mu-text-field multi-line :max-length="800" :rows="5" v-model="postFormValue.tsnr" prop="tsnr"></mu-text-field>
      </mu-form-item>
      <mu-form-item class="searchDiv animated fadeInUp" style="animation-delay: 0.5s"  label="诉求位置(请输入完整地址)" label-float prop="address" :rules="addressRules">
        <mu-text-field v-model="postFormValue.address" @change="changePostAddress" label-float :action-icon="'location_on'" :action-click="() => (setPosition())"></mu-text-field>
      </mu-form-item>
      <mu-form-item class="searchDiv animated fadeInUp" style="animation-delay: 0.1s"  label="备用电话" label-float prop="byphone">
        <mu-text-field v-model="postFormValue.byphone" :change="postFormValue.byphone = postFormValue.byphone.replace(/\s+/g,'')" prop="byphone"></mu-text-field>
      </mu-form-item>
    </mu-form>
    <div class="uploadDiv animated zoomIn" style="animation-delay: 0.9s">
        <el-upload class="uploadDivEl" action="#" :on-change="overImg" ref="my-upload" list-type="picture-card" accept="image/*" :auto-upload="false" :limit="3">
          <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" >
              <span class="el-upload-list__item-actions">
                <span class="el-upload-list__item-delete" @click="handleRemove(file)" >
                  <i class="el-icon-delete"></i>
                </span>
                <span  class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)" >
                  <i class="el-icon-zoom-in"></i>
                </span>
              </span>
            </div>
        </el-upload>
        <div class="elInput">
          <mu-text-field class="input" disabled :placeholder="imgDoneWord">
            <mu-icon value="cloud_upload" v-if="!imgDone" right></mu-icon>
            <mu-icon value="cloud_done" v-if="imgDone" right color="blue"></mu-icon>
          </mu-text-field><br/>
        </div>
      </div>
      <div class="btnZu animated zoomIn" style="animation-delay: 1.1s">
        <mu-button color="primary" full-width @click="submitUpload">提交</mu-button>
      </div>
      <el-dialog :visible.sync="dialogVisible" width="100%">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>

      <div class="showConsole" @click="showConsole"></div>
  </div>
</template>

<script>
const wx = window.wx
const BMapGL = window.BMapGL
export default {
  data () {
    return {
      clickCount: 0,
      disabled: false,
      xmRules: [
        { validate: (val) => !!val, message: '必须填写姓名' },
        { validate: (val) => val.length >= 2, message: '请填写正确的姓名' }
      ],
      phoneRules: [
        { validate: (val) => !!val, message: '必须填写手机号码' },
        { validate: (val) => val.length >= 11, message: '请填写正确的手机号码' }
      ],
      addressRules: [
        // { validate: (val) => !!val, message: '必须填写诉求地址' }
      ],
      // lxRules: [
      //   { validate: (val) => !!val, message: '必须选择类型' }
      // ],
      btRules: [
        { validate: (val) => !!val, message: '必输入标题' },
        { validate: (val) => val.length <= 20, message: '标题字数不可超过20字' }
      ],
      tsnrRules: [
        { validate: (val) => !!val, message: '投诉内容不可为空' }
      ],
      imgDone: false,
      imgDoneWord: '请选择图片(选填, 最多3张)',
      dialogImageUrl: '',
      dialogVisible: false,
      postFormValue: {
        oppenId: '',
        xm: '',
        phone: '',
        sex: 'male',
        isSecret: false,
        lx: '', // 上传类型的id
        lxName: '', // 类型的名字
        bt: '',
        tsnr: '',
        address: '',
        byphone: '',
        img: [],
        sourceType: 1,
        bd09Poi: '',
        type: this.$route.query.type
      },
      leibie: [],
      leibiehasId: []
    }
  },
  components: {},
  computed: {},
  created () {
    this.initUserOpenId()
  },
  methods: {
    showConsole () {
      var vm = this
      vm.clickCount++
      if (vm.clickCount === 10) {
        var Vconsole = this.$Vconsole
        this.Vconsole = new Vconsole()
        localStorage.setItem('openVconsole', true)
        location.reload()
      } else if (localStorage.getItem('openVconsole') === 'true') {
        localStorage.setItem('openVconsole', false)
        location.reload()
      }
    },
    handleRemove (file) {
      this.$refs['my-upload'].uploadFiles = this._.remove(this.$refs['my-upload'].uploadFiles, function (o) {
        console.log(o.uid === file.uid)
        // debugger
        return o.uid !== file.uid
      })
      this.$refs['my-upload'].uploadFiles.length < 3 && this.$jq('.el-upload').css({ display: 'inline-block' })
      this.$refs['my-upload'].uploadFiles.length < 3 && this.$jq('.el-upload-list--picture-card').css({ width: '40%', 'min-width': '165px' })
      this.$refs['my-upload'].uploadFiles.length < 1 && this.$jq('.el-upload-list--picture-card').css({ width: '0%' })
      if (this.$refs['my-upload'].uploadFiles.length < 1) {
        this.imgDone = false
        this.imgDoneWord = '请选择图片(选填)'
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    setPosition () { // 设置位置
      // this.postFormValue.address = window.address.address
      // var poi = this.$ownerUtil.getWxLocaltion()
      // console.log(poi)
      // var Vconsole = this.$Vconsole
      // this.Vconsole = new Vconsole()

      var vm = this
      var postData = {
        url: location.href.split('#')[0],
        code: this.$ownerUtil.GetRequest().code
      }
      var postUrl = 'https://xcx.gnxzhzx.com/websites/wxConfig.jsp'
      vm.$jq.post(postUrl, postData, (res) => {
        var signParam = res.data
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: signParam.appId, // 必填，公众号的唯一标识
          timestamp: signParam.timestamp, // 必填，生成签名的时间戳
          nonceStr: signParam.nonceStr, // 必填，生成签名的随机串
          signature: signParam.signature, // 必填，签名，见官网附录1
          jsApiList: ['getLocation', 'scanQRCode']
        })

        wx.ready(function () {
          wx.checkJsApi({
            jsApiList: [
              'getLocation'
            ],
            success: function (res) {
              if (!res.checkResult.getLocation) {
                alert('你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！')
                return false
              } else {
                console.log(res)
              }
            }
          })

          wx.getLocation({
            success: function (res) {
              if (res.errMsg === 'getLocation:ok') {
                var convertor = new BMapGL.Convertor()
                var COORDINATES_WGS84, COORDINATES_BD09
                convertor.translate([new BMapGL.Point(res.longitude, res.latitude)], COORDINATES_WGS84, COORDINATES_BD09, function (translateData) {
                  console.log(translateData.points)
                  vm.postFormValue.bd09Poi = {
                    ...translateData.points[0]
                  }
                  console.log(vm.postFormValue)
                  var myGeo = new BMapGL.Geocoder()
                  // 根据坐标得到地址描述
                  myGeo.getLocation(new BMapGL.Point(translateData.points[0].lng, translateData.points[0].lat), function (result) {
                    if (result) {
                      vm.postFormValue.address = result.address
                    }
                  })
                })
              } else {
                vm.postFormValue.address = '获取位置失败, 请您手动填写'
              }
            },
            cancel: function (res) {
              vm.postFormValue.address = '您拒绝获取位置, 请您手动填写'
            }
          })
        })
      }, 'json')
    },
    changePostAddress () {
      var vm = this
      var city = vm.postFormValue.address.split('市')
      console.log(city)
      var myGeo = new BMapGL.Geocoder()
      // 将地址解析结果显示在地图上，并调整地图视野
      myGeo.getPoint(vm.postFormValue.address, function (point) {
        if (point) {
          console.log(point)
          vm.postFormValue.bd09Poi = {
            ...point
          }
        } else {
          alert('您所填写的地址没有解析到结果！')
        }
      }, city[0])
    },
    submitUpload () {
      this.$refs.form.validate().then((result) => {
        console.log(result)
        if (result) {
          var infoDataLoading = this.$loading()
          var vm = this
          // var lx = this._.filter(this.leibiehasId, function (o) {
          //   return o.name === vm.postFormValue.lxName
          // })
          // this.postFormValue.lx = lx[0].id
          if (this.$refs['my-upload'].uploadFiles.length > 0) {
            this.$refs['my-upload'].uploadFiles.forEach(e => {
              var reader = new FileReader()
              reader.readAsDataURL(e.raw)
              reader.onload = function (e) {
                var imgStr = this.result.toString()
                vm.postFormValue.img.push(imgStr)
              }
            })
            setTimeout(() => {
              vm.postFun(infoDataLoading)
            }, 2000)
          } else {
            vm.postFun(infoDataLoading)
          }
        }
      })
    },
    postFun (infoDataLoading) {
      var vm = this
      console.log(vm.postFormValue)
      vm.postFormValue.bt = vm.postFormValue.bt.replace(/\s+/g, '')
      vm.$axios.post('/appeal', {
        ...vm.postFormValue,
        gdly: this.$route.query.type
      }).then(successData => {
      // vm.$axios.post('http://192.168.2.37:88/weChat/appeal', vm.postFormValue).then(successData => {
        if (successData.data.data.isSuccess) {
          infoDataLoading.close()
          this.$alert('提交成功', '', {
            okLabel: '确定'
          }).then(() => {
            vm.$router.push('/sqcx')
          })
        } else {
          this.$alert('提交失败', '', { okLabel: '确定' })
        }
      })
    },
    overImg () { // 图片设置
      var vm = this
      vm.$refs['my-upload'].uploadFiles.length > 2 && this.$jq('.el-upload').css({ display: 'none' })
      vm.$refs['my-upload'].uploadFiles.length > 0 && (vm.imgDone = !true)
      vm.$refs['my-upload'].uploadFiles.length < 2 && this.$jq('.el-upload-list--picture-card').css({ width: '40%', 'min-width': '165px' })
      vm.$refs['my-upload'].uploadFiles.length > 2 && this.$jq('.el-upload-list--picture-card').css({ width: '100%' })
      if (vm.$refs['my-upload'].uploadFiles.length > 0) {
        vm.imgDone = true
        vm.imgDoneWord = '已选择'
      }
      // if (vm.$refs['my-upload'].uploadFiles.length > 1) {
      //   this.$jq('.el-upload').css({ display: 'none' })
      // }
    },
    initFun () {
      // this.initUserOpenId()
      this.initLx()
      this.setPosition()
    },
    initUserOpenId () { // 得到用户的openId
      var vm = this
      // var userConfig = this.$ownerUtil.getAppUserCodeConfig()
      // this.postFormValue.oppenId = userConfig.openid
      // this.postFormValue.phone = userConfig.mobile
      // vm.postFormValue.oppenId = 'ovNPw5tfw6gHjZpOzVniT7KCx9lc'

      var objectData = {
        appId: this.$store.getters.getInitData.appId,
        appSecret: this.$store.getters.getInitData.appSecret
      }
      if (this.$route.query.openId) {
        this.postFormValue.oppenId = this.$route.query.openId
      } else {
        var url = `wysq_new?type=${this.$route.query.type}`
        this.postFormValue.oppenId = this.$ownerUtil.getUserCodeConfig(url, objectData)
        this.postFormValue.oppenId = this.postFormValue.oppenId.data.openId
      }

      var postData = {
        oppenId: this.postFormValue.oppenId
      }
      vm.$axios.post('/realNameCheck', postData).then(res => {
        if (!res.data.data.isSuccess) {
          vm.$router.replace({
            path: '/xxgrxx'
          })
        } else {
          vm.postFormValue.xm = res.data.data.name
          vm.postFormValue.phone = res.data.data.phone
        }
      })
    },
    initLx () { // 初始化选择类型
      var vm = this
      vm.$axios.post('/dictList').then(successData => {
        var data = successData.data
        vm.leibiehasId = data.data
        data.data.forEach(e => {
          vm.leibie.push(e.name)
        })
        // vm.leibie = data.data
      })
    }
  },
  mounted () {
    this.$parent.firstIndex = this.$route.path
    this.initFun()
  }
}
</script>
<style lang="scss">
@import '../assets/css/wysq.scss';
.showConsole {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
}
</style>
